<template>
  <the-header></the-header>
  <main v-if="isLoaded" :key="mainComponentKey">
    <the-unauthorized v-if="!isAutheticated" class="mt-3"></the-unauthorized>
    <div v-show="isAutheticated" class="container mb-4">
      <router-view />
    </div>
  </main>
  <the-footer></the-footer>
  <the-toast></the-toast>
  <the-modal></the-modal>
</template>

<script>
import TheFooter from "./components/TheFooter.vue";
import TheToast from "./components/TheToast.vue";
import TheHeader from "./components/TheHeader.vue";
import TheModal from "./components/TheModal.vue";
import useUser from "./composables/shared/useUserSingleton";
import useLanguageSingleton from "./composables/language/useLanguageSingleton";
import useToast from "./composables/shared/useToast";
import { ref, watch } from "vue";
import TheUnauthorized from './components/TheUnauthorized.vue';

export default {
  name: "App",
  components: {
    TheHeader,
    TheFooter,
    TheToast,
    TheModal,
    TheUnauthorized,
  },
  setup() {
    const { user, isAutheticated } = useUser();
    const { fetchLanguages } = useLanguageSingleton();
    const { showToast } = useToast();

    const isLoaded = ref(false);
    const mainComponentKey = ref(0);

    (async () => {
      const langResponse = await fetchLanguages();
      if (!langResponse.isSuccess) {
        showToast(langResponse.message, false);
        return;
      }
      isLoaded.value = true;
    })();

    watch(user.value, () => {
      mainComponentKey.value = ++mainComponentKey.value;
    });

    return { isLoaded, mainComponentKey, isAutheticated };
  },
};
</script>

<style lang="scss">
@import "assets/css/gs1/gs1";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

main {
  background-color: $gs1-color-gray-light;
}

button.gpc-button {
  font-size: 16px;
  font-weight: 500;
  color: $gs1-color-white;
  background-color: $gs1-color-orange;
  border-radius: 0;
  border: 0;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    color: $gs1-color-white;
    background-color: #cc3c0d;
  }

  &:disabled {
    background-color: $gs1-color-gray-med-light;

    &:hover {
      background-color: $gs1-color-gray-med-dark;
    }
  }
}
</style>
